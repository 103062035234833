body {
  font-family: Montserrat, sans-serif;
  background: #f0eff4;
  font-size: larger;
}
.upcomming-event-section .upcomming-event-carousel .item .event-item {
  position: relative;
  min-height: 120px;
  margin-top: 30px;
}
.upcomming-event-section
  .upcomming-event-carousel
  .item
  .event-item
  .countdown-timer {
  opacity: 0;
}
.upcomming-event-section
  .upcomming-event-carousel
  .item
  .event-item
  .countdown-timer
  ul {
  display: table;
  margin: 0px auto 30px;
}
.upcomming-event-section
  .upcomming-event-carousel
  .item
  .event-item
  .event-image {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
}
.upcomming-event-section
  .upcomming-event-carousel
  .item
  .event-item
  .event-image
  .post-date {
  top: 30px;
  left: 30px;
  z-index: 1;
  height: 70px;
  padding: 10px;
  min-width: 70px;
  text-align: center;
  position: absolute;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#ff3e00),
    to(#ffbe30)
  );
  background: -webkit-linear-gradient(top left, #ff3e00, #ffbe30);
  background: -o-linear-gradient(top left, #ff3e00, #ffbe30);
  background: linear-gradient(to bottom right, #ff3e00, #ffbe30);
}
.upcomming-event-section
  .upcomming-event-carousel
  .item
  .event-item
  .event-content
  .title {
  font-size: 12px;
  font-family: Montserrat, sans-serif;
  color: rgb(3, 3, 3);
  line-height: 1.2;
  font-weight: 450;
  margin: 0;
  text-transform: uppercase;
}
.upcomming-event-section
  .upcomming-event-carousel
  .owl-item.active.center
  .item
  .event-item
  .event-content
  .title {
  /* margin-bottom: 4px; */
  margin-bottom: 8px;
}
.upcomming-event-section
  .upcomming-event-carousel
  .item
  .event-item
  .event-content {
  opacity: 1;
  border-radius: 12px;
  /* background-color: #77bbff; */
  padding: 30px 5px;
  width: 82%;
  text-align: center;
  position: absolute;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-style: solid;
  border-width: 4px;
  border-color: rgb(235, 235, 235);
  border-radius: 12px;
  /* background-color: rgba(240, 239, 244); */
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}
.upcomming-event-section
  .upcomming-event-carousel
  .owl-item.active.center
  .item
  .event-item
  .countdown-timer {
  opacity: 1;
}
.upcomming-event-section .upcomming-event-carousel .owl-item.active.center {
  z-index: 1111;
}
.upcomming-event-section .upcomming-event-carousel .owl-item.active {
  z-index: 1110;
}
.upcomming-event-section .upcomming-event-carousel .owl-item {
  z-index: 1000;
}
.upcomming-event-section
  .upcomming-event-carousel
  .owl-item.active.center
  .item
  .event-item
  .event-content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  border: none;
  background-color: #77bbff;
  padding: 15px 5px;
  border-style: solid;
  border-width: 4px;
  border-color: rgb(235, 235, 235);
}
.upcomming-event-section
  .upcomming-event-carousel
  .owl-item
  .item
  .event-item
  .event-content {
  border-width: 0px;
}
.upcomming-event-section
  .upcomming-event-carousel
  .owl-item.active
  .item
  .event-item
  .event-content {
  border-width: 4px !important ;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}
.upcomming-event-section
  .upcomming-event-carousel
  .owl-item.active.center
  .item
  .event-item
  .event-content
  .MuiCheckbox-root {
  display: block;
  padding: 0px;
}
.upcomming-event-section
  .upcomming-event-carousel
  .owl-item.active.center
  .item
  .event-item
  .event-content
  .MuiCheckbox-root
  .MuiTouchRipple-root {
  display: none;
}
.upcomming-event-section
  .upcomming-event-carousel
  .owl-item.active.center
  .item
  .event-item
  .event-content
  .MuiCheckbox-root:hover,
.upcomming-event-section
  .upcomming-event-carousel
  .owl-item.active.center
  .item
  .event-item
  .event-content
  .MuiCheckbox-root:active,
.upcomming-event-section
  .upcomming-event-carousel
  .owl-item.active.center
  .item
  .event-item
  .event-content
  .MuiCheckbox-root:focus {
  background-color: initial;
}
.upcomming-event-section .owl-theme .owl-nav [class*="owl-"] {
  top: 50%;
  z-index: 1;
  margin: 0px;
  width: 46px;
  height: 46px;
  padding: 0px;
  position: absolute;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  outline: none;
  border: none;
}
.upcomming-event-section .owl-theme .owl-nav [class*="owl-"] span {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 46px;
  height: 46px;
  line-height: 33px;
  text-align: center;
  position: absolute;
  border-radius: 100%;
  font-size: 60px;
  color: #d5d5d5;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.upcomming-event-section .owl-theme .owl-nav [class*="owl-"]:before {
  /* content: '\f105'; */
  content: ">";
  display: none;
}
.upcomming-event-section .owl-theme .owl-nav [class*="owl-"] span:hover {
  color: #fff;
  background-color: #77bbff;
}
.upcomming-event-section .owl-theme .owl-nav .owl-prev {
  left: -50px;
  display: none;
}
.upcomming-event-section .owl-theme .owl-nav .owl-prev:before {
  /* content: '\f104'; */
  content: "<";
  display: none;
}
.upcomming-event-section .owl-theme .owl-nav .owl-next {
  right: -50px;
}
.owl-theme .owl-nav {
  margin-top: 0px !important;
}
.MuiDrawer-paperAnchorBottom {
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
}

.owl-dots {
  visibility: hidden;
  height: 0;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
}
.MuiCheckbox-root .MuiTouchRipple-root {
  display: none;
}
#last-page-container {
  overflow-y: auto;
  height: calc(100% + 80px);
  font-family: Montserrat, sans-serif;
}
.MuiInput-underline::before,
.MuiInput-underline::after {
  display: none;
}
.MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
.MuiIconButton-root {
  border-radius: 0% !important;
}
.MuiIconButton-label {
  border-radius: 0% !important;
}
@keyframes header {
  from {
    margin-left: -150px;
  }
  to {
    margin-left: 10;
  }
}
@keyframes step_root {
  from {
    left: -30px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}
@keyframes details {
  from {
    right: -30%;
  }
  to {
    right: 10px;
  }
}
@keyframes stepTwo {
  from {
    margin-left: 100px;
  }
  to {
    margin-left: 10;
  }
}
@keyframes stepFive {
  from {
    right: 10px;
    display: block;
  }
  to {
    right: -30%;
    display: none;
  }
}
@keyframes step7Img {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes body {
  from {
    left: 0%;
  }
  to {
    left: -10%;
  }
}
@keyframes bodyStep5 {
  from {
    left: -10%;
  }
  to {
    left: 0%;
  }
}
@keyframes pulse {
  0% {
    color: black;
  }

  50% {
    color: #77bbff;
  }
  100% {
    color: black;
  }
}
@media (max-width: 1100px) {
  .upcomming-event-section
    .upcomming-event-carousel
    .item
    .event-item
    .event-content {
    width: 75%;
  }
}
@media (max-width: 768px) {
  body {
    font-family: Montserrat, sans-serif;
    background: #f0eff4;
    font-size: larger;
  }
  .upcomming-event-section
    .upcomming-event-carousel
    .item
    .event-item
    .event-content
    .title {
    font-size: 9px;
  }
  .upcomming-event-section .owl-theme .owl-nav [class*="owl-"] span {
    font-size: 40px;
    line-height: 21px;
    width: 30px;
    height: 30px;
  }
  .upcomming-event-section .owl-theme .owl-nav [class*="owl-"] span:hover {
    color: #fff;
    background-color: #77bbff;
  }
  .upcomming-event-section .upcomming-event-carousel .item .event-item {
    position: relative;
    min-height: 70px;
    margin-top: 30px;
  }
  .upcomming-event-section
    .upcomming-event-carousel
    .item
    .event-item
    .event-content {
    border-radius: 5px;
    padding: 16px 5px;
    border-width: 2px;
  }
  .upcomming-event-section .owl-theme .owl-nav [class*="owl-"] {
    margin: 10px;
    width: 30px;
    height: 30px;
  }
  .upcomming-event-section
    .upcomming-event-carousel
    .owl-item.active.center
    .item
    .event-item
    .event-content {
    padding: 7px 5px;
  }
  .upcomming-event-section
    .upcomming-event-carousel
    .owl-item.active.center
    .item
    .event-item
    .event-content
    .MuiCheckbox-root {
    margin-top: -8px;
  }

  .upcomming-event-section
    .upcomming-event-carousel
    .item
    .event-item
    .event-content {
    width: 80%;
  }
}
@media (max-width: 453px) {
  .upcomming-event-section
    .upcomming-event-carousel
    .item
    .event-item
    .event-content {
    width: 75%;
  }
}
.makeStyles-moveIcons-77 button span {
  margin: 0;
  display: flex;
  justify-content: center;
}

@media (max-height: 640px) {
}

@media (max-height: 812px) and (min-height: 641px) {
}
